import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {User} from '../../models/user';
import {map} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/auth';

export interface UserId extends User {
  id: string;
}

@Injectable({
  providedIn: 'root'
})

export class PreRegistroService {
  public preRegistroCollection: AngularFirestoreCollection<User>;
  public usersPreRegistro: Observable<UserId[]>;
  res: any;

  constructor(public afs: AngularFirestore, private afAuth: AngularFireAuth) {
    this.preRegistroCollection = this.afs.collection<User>('pre-registro');
    this.usersPreRegistro = this.preRegistroCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as UserId;
        const id = a.payload.doc.id;
        return {id, ...data};
      }))
    );
  }

  public add(user: User) {
    return this.preRegistroCollection.add({...user});
  }

  public get(): Observable<UserId[]> {
    return this.usersPreRegistro;
  }

  public getid(id) {
    return this.afs.collection('pre-registro', ref => ref.where('uid', '==', id)).valueChanges();
  }

  public remove(id: string): Promise<void> {
    return this.preRegistroCollection.doc(id).delete();
  }

  public updatetipe(email: string, tipo) {
    this.afs.collection('pre-registro', ref => ref.where('email', '==', email)).auditTrail().subscribe(res => {
      this.res = res[0].payload.doc.id;
      console.log(this.res);
      return this.preRegistroCollection.doc(this.res).update({'roles': tipo}).then((res: any) => {
        console.log(res);
      }).catch(error => {
        console.log(error);
      });
    });
  }

  public updateimg(email: string, images) {
    this.afs.collection('pre-registro', ref => ref.where('email', '==', email)).auditTrail().subscribe(res => {
      this.res = res[0].payload.doc.id;
      return this.preRegistroCollection.doc(this.res).update({'photoURL': images});
    });
  }

  public update(email: string, user: User) {
    // if (user) {
    //   const user2 = this.afAuth.auth.currentUser;
    //   user2.updateProfile({
    //     displayName: name,
    //     // photoURL: 'null'// some photo url
    //   }).then((error ) => {
    //     console.log(error);
    //   });
    // }
    this.afs.collection('pre-registro', ref => ref.where('email', '==', email)).auditTrail().subscribe(res => {
      this.res = res[0].payload.doc.id;
      this.preRegistroCollection.doc<User>(this.res).update({...user}).then(r => {
        console.log(r);
        return res;
      });
      return res;
    });
  }

  public updatecity(city, email) {
    this.afs.collection('pre-registro', ref => ref.where('email', '==', email)).auditTrail().subscribe(res => {
      this.res = res[0].payload.doc.id;
      return this.preRegistroCollection.doc(this.res).update({'city': city});
    });
  }

  public verific(email: string) {
    return this.afs.collection('pre-registro', ref => ref.where('email', '==', email)).valueChanges();
  }
}
