import {EventEmitter, Injectable, Output} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {auth} from 'firebase/app';
import {ToastService} from '../toast/toast.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {PreRegistroService} from '../pre-registro/pre-registro.service';
import {User} from '../../models/user';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData: any = null;
  @Output() getLoggedInData: EventEmitter<any> = new EventEmitter();
  @Output()
  propagar = new EventEmitter<string>();

  constructor(
    private afAuth: AngularFireAuth,
    private toastService: ToastService,
    public afs: AngularFirestore,
    public  prer: PreRegistroService,
    private router: Router
  ) {
    // this.afAuth.authState.subscribe(user => {
    //     if (user) {
    //       this.userData  =  {
    //         displayName: user.displayName,
    //         email : user.email,
    //         emailVerified: user.emailVerified,
    //         photoURL: user.photoURL,
    //         uid: user.uid,
    //         providerData: user.providerData
    //       };
    //       console.log('user logged', user);
    //     }
    // });
  }

  public createmail(email) {
    return this.afAuth.auth.fetchSignInMethodsForEmail(email).then(res => {
      console.log(res);
    }).catch(error => {
      console.log(error);
    });
  }

  public createUserWithEmailAndPassword(email: string, password: string, name: string, phone: any): Promise<auth.UserCredential> {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password).then((user: any) => {
      console.log(user);
      if (user) {
        const user2 = this.afAuth.auth.currentUser;
        user2.updateProfile({
          displayName: name,
          // photoURL: 'null'// some photo url
        }).then((error) => {
          console.log(error);
        });
      }
      return user;
    }).catch((error: any) => {

    });
    /* .then(credentials => {
               console.log('credentials', credentials);
               // guardar datos del usuario en la DB.
               const user: UserDb = {
                   create: new Date().getTime(),
                   email,
                   name: this.userRegister.name,
                   phone: this.userRegister.phone,
                   roles: ['user'],
                   update: new Date().getTime(),
                   uid: credentials.user.uid
               };
               this.userService.create(user);
               this.toastService.showSuccess('Usuario creado correctamente.');
           }).catch(error => {
               console.log('error create user with email and password', error);
               this.toastService.showDanger(error.message);
           }); */
  }

  // loginEmail(email: string, pass: string): Promise<auth.UserCredential> {
  //     return this.afAuth.auth.signInWithEmailAndPassword(email, pass);
  // }

  loginFacebook(): Promise<auth.UserCredential> {
    const provider = new auth.FacebookAuthProvider();
    provider.addScope('email');
    return this.afAuth.auth.signInWithPopup(provider);
    // return this.afAuth.auth.signInWithPopup(new auth.FacebookAuthProvider());
  }

  loginGoogle(): Promise<auth.UserCredential> {
    return this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider());
  }

  loginTwitter(): Promise<auth.UserCredential> {
    return this.afAuth.auth.signInWithPopup(new auth.TwitterAuthProvider());
  }

  logout(): void {
    localStorage.clear();
    this.getLoggedInData.emit('logout');
    this.afAuth.auth.signOut();
    this.router.navigateByUrl('/login');
  }

  public signInWithEmailAndPassword(email: string, password: string): Promise<auth.UserCredential> {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  register() {
  }

  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail);
  }

  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification();
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user);
    return (user !== null) ? true : false;
  }

  async SetUserData() {
    await this.afAuth.authState.subscribe((user: any) => {
      if (user) {
        this.userData = {
          displayName: user.displayName,
          name: user.name ? user.name : user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          uid: user.uid,
          providerData: user.providerData
        };
        let data = this.prer.verific(user.email).subscribe((res: any) => {
          if (res.length === 0) {
            this.prer.add(new User(
              user.email,
              user.displayName ? user.displayName : user.name,
              null,
              null,
              null,
              null,
              null,
              user.uid,
              null
            )).then(
              (credentials: any) => {
                this.toastService.showSuccess('User logged');
              }
            ).catch(
              // error => this.toastService.showDanger(error.message)
            );
          } else {
            console.log(res[0]);
            this.userData.business = res[0].business;
            this.userData.photoURL = res[0].photoURL;
            this.userData.phone = res[0].phone;
            this.userData.roles = res[0].roles;
            this.userData.name = res[0].name;
            if (!this.userData.name) {
              this.userData.name = user.displayName;
            }
            this.userData.city = res[0].city;
            localStorage.setItem('user', JSON.stringify(this.userData));
            console.log(this.userData.nolog);
            console.log(this.userData);
            const use = this.userData;
            this.emit2(use);
            return use;
          }
        });
      }
    });
  }

  public emitlogin(data) {
    this.getLoggedInData.emit(data);
  }

  public emit2(data) {
    this.getLoggedInData.emit(data);
  }

}
