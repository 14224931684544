import { Component, OnInit } from '@angular/core';
import {WelcometourComponent} from '../../pages/web/welcometour/welcometour.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  bsModalRef: BsModalRef;
  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }
  openModalWithComponent(tipe) {
    const initialState = {
      type: tipe,
    };
    this.bsModalRef = this.modalService.show(
      WelcometourComponent,
      Object.assign({initialState},  {class: ' modal-md modalblue'})
    );
  }

}
