import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {PresupuestoM} from '../../models/presupuesto';
import {User} from '../../models/user';


@Injectable({
  providedIn: 'root'
})
export class ReferidosService {
  public familiesCollection: AngularFirestoreCollection;
  public familiesCollectionsolids: AngularFirestoreCollection;
  constructor(public afs: AngularFirestore) {
    this.familiesCollectionsolids = this.afs.collection('list_solid_referrals');
    this.familiesCollection = this.afs.collection('referrals');
  }

  public add(data) {
    return this.familiesCollectionsolids.add(data);
  }
  public addrefer(data) {
    console.log(data);
    return this.familiesCollection.add(data);
  }

  public get(id) {
    return this.afs.collection('referrals', ref => ref.where('id_referente', '==', id));
  }
  public getverification(id) {
    return this.afs.collection('referrals', ref => ref.where('id_referido', '==', id));
  }
  public getid(id) {
    return this.afs.collection('pre-registro', ref => ref.where('uid', '==', id));
  }

  public consuldate(id) {
    return this.afs.collection('pre-registro', ref => ref.where('uid', '==', id));
  }
}
