import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpClientModule, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss']
})
export class SendEmailComponent implements OnInit {
  public headers: HttpHeaders;
  constructor(public http: HttpClient, private router: Router) {
    this.headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });

  }

  ngOnInit() {
  }

  sendEmail() {
    // return this.check(this.http.post(this.getUrl(endpoint, params), body, {headers: this.headers}).toPromise());
    const url = `https://us-central1-todo-especialistas.cloudfunctions.net/emailMessage`;
    const params: URLSearchParams = new URLSearchParams();
     // const headers = new Headers({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });
    const body = {
      name: 'yonathan',
      message: '<h1>hola que tal todo bien</h1>'
    }

    return this.http.post(url, body, {headers: this.headers}).toPromise()
      .then( res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  }

}
