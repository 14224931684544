import {Component, OnInit} from '@angular/core';
import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import {customPatternValidator} from '../../validators/pattern.validator';
import {AuthService} from 'src/app/services/auth/auth.service';
import {ToastService} from 'src/app/services/toast/toast.service';
import {UtilsService} from '../../services/utils/utils.service';
import {PreRegistroService} from '../../services/pre-registro/pre-registro.service';
import {User} from '../../models/user';
import {ActivatedRoute, Router} from '@angular/router';
import {__await} from 'tslib';
import * as firebase from 'firebase/app';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {ReferidosService} from '../../services/referidos/referidos.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {
  // formRegister: FormGroup;
  public formRegister: any = FormGroup;
  public password2: any;
  public checking: any = false;
  public idrefer: any;
  emailexiste: any = false;
  loading: any = false;

  constructor(private authService: AuthService,
              private toastService: ToastService,
              private utilsService: UtilsService,
              private router: Router,
              private registerService: PreRegistroService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              public referservices: ReferidosService
  ) {
  }

  ngOnInit() {
    this.idrefer = this.route.snapshot.paramMap.get('refer');
    console.log('ubicacion');
    this.formRegister = this.fb.group({
      email: [null, Validators.compose([Validators.required, Validators.email, customPatternValidator(this.utilsService.patterns.email)])],
      password: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      password2: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      name: [null, Validators.compose([Validators.required])],
      phone: [null, Validators.compose([Validators.required])],
      checking: [null, Validators.compose([Validators.required])],
    });

  }

  public async loginEmailregister() {

    if (this.password2 === this.formRegister.value.password) {
      this.loading = true;
      const data = this.registerService.verific(this.formRegister.value.email).subscribe((res: any) => {
        if (res[0]) {
          this.loading = false;
          if (this.emailexiste === false) {
            this.emitirm();
          }
        } else {
          // loguiar primero y luego registrar
          this.authService.createUserWithEmailAndPassword(this.formRegister.value.email, this.formRegister.value.password,
            this.formRegister.value.name, this.formRegister.value.phone).then(
            credentials => {
              if (credentials) {
                console.log(credentials.user.uid);
                this.authService.SendVerificationMail();
                this.addpreregistro(credentials.user.uid);
                this.loading = false;
                this.toastService.showSuccess('Register succes');
              }
            }
          ).catch(
            (error) => {
              this.toastService.showDanger(error.message);
              this.loading = false;
            });
        }
      });
    } else {
      this.loading = false;
      this.toastService.showDanger('Las contraseñas no son iguales');
    }

  }

  emitirm() {
    this.toastService.showSuccess('Ya estas registrado');
    this.emailexiste = true;
  }

  public verificar() {
    this.authService.userData();

  }

  addrefer(id) {
    const data = {
      id_referente: this.idrefer,
      email_referido: this.formRegister.value.email,
      id_referido: id,
      status: true
    };
    this.referservices.addrefer(data).then(res => {
      console.log(res);
    });
  }

  public loginRedes(red: string) {
    if (red === 'facebook') {
      this.loading = true;
      this.authService.loginFacebook().then((data: any) => {
        this.loading = false;
        const res = this.authService.SetUserData();
      }).catch((error: any) => {
        console.log(error);
        this.loading = false;
        this.toastService.showDanger('Algo salio mal');
      });
    }
    if (red === 'google') {
      this.loading = true;
      this.authService.loginGoogle().then((data: any) => {
        this.loading = false;
        this.authService.SetUserData();
      }).catch((error: any) => {
        console.log(error);
        this.loading = false;
        this.toastService.showDanger('Algo salio mal');
      });
    }
    if (red === 'twitter') {
      this.authService.loginTwitter();
    }
    // this.toastService.showInfo(`Proximamente login con tu red favorita: ${red}`);
  }

  async addpreregistro(id) {
    if (this.idrefer) {
      this.loading = false;
      await this.addrefer(id);
    }
    this.registerService.add(new User(
      this.formRegister.value.email,
      this.formRegister.value.name,
      this.formRegister.value.phone,
      null,
      null,
      null,
      null,
      id,
      null
    )).then((credentials: any) => {
        const res = this.authService.SetUserData();
        if (!this.idrefer) {
          this.loading = false;
          this.router.navigateByUrl('/home');
        } else {
          this.loading = false;
          this.router.navigateByUrl('/web/suscripcion');
        }
      }
    ).catch(
      (error: any) => {
       this.toastService.showDanger(error.message);
       this.loading = false;
      }
    );
  }
}
