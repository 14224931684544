import { AbstractControl, ValidatorFn } from '@angular/forms';

export function customPatternValidator(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const value = control.value;
        if (value === '') {
            return null;
        }
        return !regexp.test(value) ? { patternInvalid: { regexp } } : null;
    };
}
