import { Component, OnInit, AfterViewInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Router} from '@angular/router';
@Component({
  selector: 'app-welcometour',
  templateUrl: './welcometour.component.html',
  styleUrls: ['./welcometour.component.scss']
})
export class WelcometourComponent implements OnInit, AfterViewInit {
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i  class="fa fa-chevron-right marginri"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  };
  modalview: any = false;

  type: any;

  constructor(private bsModalRef: BsModalRef,  private router: Router) { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.modalview = true;
  }
  suscripcion() {
    this.router.navigateByUrl('/web/suscripcion');
    this.bsModalRef.hide();
  }
  hidemodal() {
    this.bsModalRef.hide();
  }

}
