import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';

import { BootstrapModule } from './bootstrap.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from './material.module';
import { FontAwesomeLibraryModule } from './font-awesome-library.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalComponetsModule } from './components/global-componets.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import {NgSelectModule} from '@ng-select/ng-select';
import {NavbarComponent} from './components/navbar/navbar.component';
import {FooterComponent} from './components/footer/footer.component';
import {ResetPasswordComponent} from './pages/web/reset-password/reset-password.component';
import {AuthService} from './services/auth/auth.service';
import {AuthGuard} from './guards/auth.guard';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {AngularGooglePlaceModule} from 'angular-google-place';
import {TruncatePipesModule} from 'angular-truncate-pipes';
import {ReferidosService} from './services/referidos/referidos.service';
import {ListBusinessComponent} from './pages/web/list-business/list-business.component';
import {TruncateModule} from 'ng2-truncate';
import {ImageCropperModule} from 'ngx-image-cropper';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {AngularFontAwesomeModule} from 'angular-font-awesome';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        NavbarComponent,
        FooterComponent,
        ResetPasswordComponent

    ],
    imports: [
        AngularFontAwesomeModule,
        TruncateModule,
        SweetAlert2Module.forRoot(),
        ImageCropperModule,
        BrowserModule,
        TruncatePipesModule,
        AngularGooglePlaceModule,
        Ng2TelInputModule,
        BrowserAnimationsModule,
        ModalModule.forRoot(),
        HttpClientModule,
        // Routing App
        AppRoutingModule,
        // config Firebase and AngularFire
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        // iconos
        FontAwesomeLibraryModule,
        // componentes comunes
        GlobalComponetsModule,
        // bootstrap
        NgbModule,
        BootstrapModule,
        // Angular Material
        MaterialModule,
        FormsModule,
        NgSelectModule,
        ReactiveFormsModule,
        ModalModule.forRoot()
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    providers: [AuthService, AuthGuard, ReferidosService],
    bootstrap: [AppComponent]
})
export class AppModule { }
