import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BootstrapModule } from '../bootstrap.module';
import { MaterialModule } from '../material.module';
import { Page404Component } from './page404/page404.component';
import { ToastsContainerComponent } from './toast-container/toast-container.componet';
import { FooterComponent } from './footer/footer.component';
import {WelcometourComponent} from '../pages/web/welcometour/welcometour.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import { SearchaddressComponent } from './searchaddress/searchaddress.component';
import {AngularGooglePlaceModule} from 'angular-google-place';
import { SendEmailComponent } from './send-email/send-email.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';


@NgModule({
    declarations: [
        Page404Component,
        ToastsContainerComponent,
      WelcometourComponent,
      SendEmailComponent,


    ],
    exports: [
        Page404Component,
        ToastsContainerComponent,
    ],
    imports: [
        AngularGooglePlaceModule,
        CommonModule,
        HttpClientModule,
        BootstrapModule,
        MaterialModule,
        CarouselModule
    ],
    entryComponents: [WelcometourComponent],
})
export class GlobalComponetsModule { }
