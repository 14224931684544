import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth/auth.service';
import {ToastService} from '../../../services/toast/toast.service';
import {UtilsService} from '../../../services/utils/utils.service';
import {customPatternValidator} from '../../../validators/pattern.validator';
import {Router} from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  constructor(private authService: AuthService,
              private toastService: ToastService,
              private utilsService: UtilsService,
              private fb: FormBuilder,
              private router: Router) { }

  ngOnInit() {
    this.form = this.fb.group({
      email : [null, Validators.compose([Validators.required, Validators.email, customPatternValidator(this.utilsService.patterns.email)])],
    });
  }
  public loginreset() {
    this.authService.ForgotPassword(
      this.form.value.email,
    ).then(
      (credentials: any) => {
        this.form.reset();
        this.router.navigateByUrl('/login');
        this.toastService.showSuccess('Email enviado');
      }).catch(
      error => this.toastService.showDanger(error.message)
    );
  }

}
