import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {WelcometourComponent} from '../../pages/web/welcometour/welcometour.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  bsModalRef: BsModalRef;
  rol: null;
  isLogged = false;
  links: any[] = [
    // {
    //   disabled: false,
    //   label: 'Link',
    //   link: '/dash/home',
    //   sub: null
    // },
    {
      disabled: false,
      label: 'Comercial',
      sub: [
        {
          disabled: false,
          divider: false,
          label: 'Mis clientes',
          link: 'dash/trading'
        },
        {
          disabled: false,
          divider: false,
          label: 'Contabilidad',
          link: 'dash/trading/contabilidad'
        },
      ]
    },
    // {
    //   disabled: false,
    //   label: 'Admin',
    //   link: '',
    //   sub: [
    //     {
    //       disabled: false,
    //       divider: false,
    //       label: 'Action',
    //       link: ''
    //     },
    //   ]
    // },
    // {
    //   disabled: true,
    //   label: '',
    //   link: '/login',
    //   sub: null
    // }
  ];
  nonecel: any;
  ruta: any = false;
  userdata: any = [];
  userlog = false;
  tradelog = false;
  businesslog = false;
  adminlog = false;
  nologlog = false;
  public roles?: Array<'user' | 'trade' | 'business' | 'admin' | 'nolog'>;

  constructor(private modalService: BsModalService, private router: Router, private auth: AuthService, private authService: AuthService,
              private route: ActivatedRoute) {
    auth.getLoggedInData.subscribe(data => this.changeName(data));
    console.log('asdasdasdasd');
    // this.route.params.subscribe(routeParams => {
    //   console.log('llegue aqui');
    // });
  }

  async ngOnInit() {
    this.userdata = JSON.parse(localStorage.getItem('user'));
    if (this.userdata) {
      this.userdata = this.userdata = JSON.parse(localStorage.getItem('user'));
      this.rol = this.userdata.roles;
      if (this.rol) {
        await this.userdata.roles.forEach((actions: any, index) => {
          if (actions === 'business') {
            this.businesslog = true;
          }
          if (actions === 'user') {
            this.userlog = true;
          }
          if (actions === 'trade') {
            this.tradelog = true;
          }
          if (actions === 'admin') {
            this.adminlog = true;
          }
        });
      }
      console.log(this.userdata);
    }
    this.ruta = this.router.url;
    console.log(this.ruta);
  }


  private changeName(data: any): void {
    if (data === 'logout') {
      this.userdata = null;
      this.ngOnInit();
    } else {
      this.userdata = JSON.parse(localStorage.getItem('user'));
      if (this.userdata) {
        this.rol = this.userdata.roles;
        if (this.rol) {
          this.userdata.roles.forEach((actions: any, index) => {
            if (actions === 'business') {
              this.businesslog = true;
            }
            if (actions === 'user') {
              this.userlog = true;
            }
            if (actions === 'trade') {
              this.tradelog = true;
            }
            if (actions === 'admin') {
              this.adminlog = true;
            }
          });
        }
        this.userdata = this.userdata = JSON.parse(localStorage.getItem('user'));
      }
      // this.userdata = data;
    }
  }

  irlupa() {
    if (this.userdata.city) {
      this.router.navigateByUrl('web/filter/' + this.userdata.city);
    } else {
      this.router.navigateByUrl('web/home');
    }
  }

  openModalWithComponent(tipe) {
    this.closeNav();
    const initialState = {
      type: tipe,
    };
    this.bsModalRef = this.modalService.show(
      WelcometourComponent,
      Object.assign({initialState}, {class: ' modal-md modalblue'})
    );
  }

  openNav() {
    this.nonecel = true;
    document.getElementById('mySidenav').style.width = '250px';
    document.getElementById('main').style.marginLeft = '250px';
  }

  closeNav() {
    this.nonecel = false;
    document.getElementById('mySidenav').style.width = '0';
    document.getElementById('main').style.marginLeft = '0';
  }

  logout() {
    this.rol = null;
    this.authService.logout();
  }

}
