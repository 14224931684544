import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { Page404Component } from './components/page404/page404.component';
import { RegisterComponent } from './pages/register/register.component';
import {ResetPasswordComponent} from './pages/web/reset-password/reset-password.component';
import {AuthGuard} from './guards/auth.guard';
import {SendEmailComponent} from './components/send-email/send-email.component';

const routes: Routes = [
    { path: 'dash', loadChildren: './pages/dash/dash.module#DashModule', canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'email', component: SendEmailComponent},
    { path: 'page-404', component: Page404Component },
    { path: 'registro', component: RegisterComponent  },
    { path: 'registro/:refer', component: RegisterComponent  },
    {path: 'resetpassword', component: ResetPasswordComponent},
    { path: 'web', loadChildren: './pages/web/web.module#WebModule' },
    { path: '**', redirectTo: 'web' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
