import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    toasts: any[] = [];

    show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
        this.toasts.push({ textOrTpl, ...options });
    }

    showDanger(message: string, delay?: number) {
        delay = delay ? delay : 5000;
        this.show(message, { classname: 'bg-danger text-light', delay });
    }

    showInfo(message: string, delay?: number) {
        delay = delay ? delay : 5000;
        this.show(message, { classname: 'bg-primary text-light', delay });
    }

    showSuccess(message: string, delay?: number) {
        delay = delay ? delay : 5000;
        this.show(message, { classname: 'bg-success text-light', delay });
    }

    remove(toast) {
        this.toasts = this.toasts.filter(t => t !== toast);
    }
}
