import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    constructor() { }

    // tslint:disable: max-line-length
    public patterns = {
        divisa: /^[\d]+([.][\d]{1,3})?$/,
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        letter: /^[a-zA-Z]+$/,
        number: /^[\d]+$/,
    };
    // tslint:enable: max-line-length

    public randAlfaNumeric(size?: number): string {
        size = size ? size : 10;
        let alfaNumeric = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < size; i++) {
            alfaNumeric += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return alfaNumeric;
    }

    public base64ImageToFile(base64: string, type: string): File {
        const date = new Date().getTime();
        // Replace extension according to your media type
        const imageName = `${date}.${this.randAlfaNumeric(5)}.${type}`;
        // call method that creates a blob from dataUri
        const imageBlob = this.dataURItoBlob(base64, type);
        return new File([imageBlob], imageName, { type: `image/${type}` });
    }

    public dataURItoBlob(dataURI: string, type: string): Blob {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        return new Blob([int8Array], { type: `image/${type}` });
    }

    public normaliceString(str: string): string {
        // tslint:disable: max-line-length
        const a = ['À', 'Á', 'Â', 'Ã', 'Ä', 'Å', 'Æ', 'Ç', 'È', 'É', 'Ê', 'Ë', 'Ì', 'Í', 'Î', 'Ï', 'Ð', 'Ñ', 'Ò', 'Ó', 'Ô', 'Õ', 'Ö', 'Ø', 'Ù', 'Ú', 'Û', 'Ü', 'Ý', 'ß', 'à', 'á', 'â', 'ã', 'ä', 'å', 'æ', 'ç', 'è', 'é', 'ê', 'ë', 'ì', 'í', 'î', 'ï', 'ñ', 'ò', 'ó', 'ô', 'õ', 'ö', 'ø', 'ù', 'ú', 'û', 'ü', 'ý', 'ÿ', 'Ā', 'ā', 'Ă', 'ă', 'Ą', 'ą', 'Ć', 'ć', 'Ĉ', 'ĉ', 'Ċ', 'ċ', 'Č', 'č', 'Ď', 'ď', 'Đ', 'đ', 'Ē', 'ē', 'Ĕ', 'ĕ', 'Ė', 'ė', 'Ę', 'ę', 'Ě', 'ě', 'Ĝ', 'ĝ', 'Ğ', 'ğ', 'Ġ', 'ġ', 'Ģ', 'ģ', 'Ĥ', 'ĥ', 'Ħ', 'ħ', 'Ĩ', 'ĩ', 'Ī', 'ī', 'Ĭ', 'ĭ', 'Į', 'į', 'İ', 'ı', 'Ĳ', 'ĳ', 'Ĵ', 'ĵ', 'Ķ', 'ķ', 'Ĺ', 'ĺ', 'Ļ', 'ļ', 'Ľ', 'ľ', 'Ŀ', 'ŀ', 'Ł', 'ł', 'Ń', 'ń', 'Ņ', 'ņ', 'Ň', 'ň', 'ŉ', 'Ō', 'ō', 'Ŏ', 'ŏ', 'Ő', 'ő', 'Œ', 'œ', 'Ŕ', 'ŕ', 'Ŗ', 'ŗ', 'Ř', 'ř', 'Ś', 'ś', 'Ŝ', 'ŝ', 'Ş', 'ş', 'Š', 'š', 'Ţ', 'ţ', 'Ť', 'ť', 'Ŧ', 'ŧ', 'Ũ', 'ũ', 'Ū', 'ū', 'Ŭ', 'ŭ', 'Ů', 'ů', 'Ű', 'ű', 'Ų', 'ų', 'Ŵ', 'ŵ', 'Ŷ', 'ŷ', 'Ÿ', 'Ź', 'ź', 'Ż', 'ż', 'Ž', 'ž', 'ſ', 'ƒ', 'Ơ', 'ơ', 'Ư', 'ư', 'Ǎ', 'ǎ', 'Ǐ', 'ǐ', 'Ǒ', 'ǒ', 'Ǔ', 'ǔ', 'Ǖ', 'ǖ', 'Ǘ', 'ǘ', 'Ǚ', 'ǚ', 'Ǜ', 'ǜ', 'Ǻ', 'ǻ', 'Ǽ', 'ǽ', 'Ǿ', 'ǿ'];
        const b = ['A', 'A', 'A', 'A', 'A', 'A', 'AE', 'C', 'E', 'E', 'E', 'E', 'I', 'I', 'I', 'I', 'D', 'N', 'O', 'O', 'O', 'O', 'O', 'O', 'U', 'U', 'U', 'U', 'Y', 's', 'a', 'a', 'a', 'a', 'a', 'a', 'ae', 'c', 'e', 'e', 'e', 'e', 'i', 'i', 'i', 'i', 'n', 'o', 'o', 'o', 'o', 'o', 'o', 'u', 'u', 'u', 'u', 'y', 'y', 'A', 'a', 'A', 'a', 'A', 'a', 'C', 'c', 'C', 'c', 'C', 'c', 'C', 'c', 'D', 'd', 'D', 'd', 'E', 'e', 'E', 'e', 'E', 'e', 'E', 'e', 'E', 'e', 'G', 'g', 'G', 'g', 'G', 'g', 'G', 'g', 'H', 'h', 'H', 'h', 'I', 'i', 'I', 'i', 'I', 'i', 'I', 'i', 'I', 'i', 'IJ', 'ij', 'J', 'j', 'K', 'k', 'L', 'l', 'L', 'l', 'L', 'l', 'L', 'l', 'l', 'l', 'N', 'n', 'N', 'n', 'N', 'n', 'n', 'O', 'o', 'O', 'o', 'O', 'o', 'OE', 'oe', 'R', 'r', 'R', 'r', 'R', 'r', 'S', 's', 'S', 's', 'S', 's', 'S', 's', 'T', 't', 'T', 't', 'T', 't', 'U', 'u', 'U', 'u', 'U', 'u', 'U', 'u', 'U', 'u', 'U', 'u', 'W', 'w', 'Y', 'y', 'Y', 'Z', 'z', 'Z', 'z', 'Z', 'z', 's', 'f', 'O', 'o', 'U', 'u', 'A', 'a', 'I', 'i', 'O', 'o', 'U', 'u', 'U', 'u', 'U', 'u', 'U', 'u', 'U', 'u', 'A', 'a', 'AE', 'ae', 'O', 'o'];
        let i = a.length;
        while (i--) { str = str.replace(a[i], b[i]); }
        return str;
        // tslint:enable: max-line-length
    }

    public sortArrayByKey(arr: any[], key: string, orderingAsc?: boolean): any[] {
        orderingAsc = orderingAsc ? orderingAsc : true;
        if (!isNaN(arr[0][key])) {
            return this.sortArrayByKeyNumber(arr, key, orderingAsc);
        }
        arr.sort((a, b) => {
            if (a[key] && b[key]) {
                if (a[key].toLowerCase() < b[key].toLowerCase()) {
                    return orderingAsc ? -1 : 1;
                }
                if (a[key].toLowerCase() > b[key].toLowerCase()) {
                    return orderingAsc ? 1 : -1;
                }
            }
            return 0;
        });
        return arr;
    }

    public sortArrayByKeyNumber(arr: any[], key: string, orderingAsc?: boolean): any[] {
        orderingAsc = orderingAsc ? orderingAsc : true;
        if (isNaN(arr[0][key])) {
            return this.sortArrayByKey(arr, key, orderingAsc);
        }
        arr.sort((a, b) => {
            if (a[key] && b[key]) {
                if (a[key] < b[key]) {
                    return orderingAsc ? -1 : 1;
                }
                if (a[key] > b[key]) {
                    return orderingAsc ? 1 : -1;
                }
            }
            return 0;
        });
        return arr;
    }

    public uniqueString(arr: any[]): any[] {
        const unique = (value: any, i: number, self: any[]) => {
            return self.indexOf(value) === i;
        }
        arr = arr.filter(unique);
        const r = [];
        arr.forEach(a => {
            r.push(a);
        });
        return r;
    }

    public uniqueObject(arr: any[], sort?: { key: string, orderingAsc?: boolean }): any[] {
        const unique = [];
        let r = [];
        arr.forEach(a => {
            if (unique.indexOf(a.value) === -1) {
                unique.push(a.value);
                r.push(a);
            }
        });
        if (sort) {
            r = this.sortArrayByKey(r, sort.key, sort.orderingAsc);
        }
        return r;
    }
}
