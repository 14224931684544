import { NgModule } from '@angular/core';
import {
    NgbAccordionModule,
    NgbAlertModule,
    NgbCarouselModule,
    NgbButtonsModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbPopoverModule,
    NgbProgressbarModule,
    NgbRatingModule,
    NgbTabsetModule,
    NgbTimepickerModule,
    NgbToastModule,
    NgbTooltipModule,
    NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    exports: [
        NgbAccordionModule,
        NgbAlertModule,
        NgbCarouselModule,
        NgbButtonsModule,
        NgbCollapseModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        NgbModalModule,
        NgbPaginationModule,
        NgbPopoverModule,
        NgbProgressbarModule,
        NgbRatingModule,
        NgbTabsetModule,
        NgbTimepickerModule,
        NgbToastModule,
        NgbTooltipModule,
        NgbTypeaheadModule
    ],
    imports: [
        NgbAccordionModule,
        NgbAlertModule,
        NgbCarouselModule,
        NgbButtonsModule,
        NgbCollapseModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        NgbModalModule,
        NgbPaginationModule,
        NgbPopoverModule,
        NgbProgressbarModule,
        NgbRatingModule,
        NgbTabsetModule,
        NgbTimepickerModule,
        NgbToastModule,
        NgbTooltipModule,
        NgbTypeaheadModule
    ]
})

export class BootstrapModule { }
