import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {customPatternValidator} from '../../validators/pattern.validator';
import {AuthService} from 'src/app/services/auth/auth.service';
import {ToastService} from 'src/app/services/toast/toast.service';
import {UtilsService} from '../../services/utils/utils.service';
import {Router} from '@angular/router';
import {PreRegistroService} from '../../services/pre-registro/pre-registro.service';
import {User} from '../../models/user';
import {BsModalRef} from 'ngx-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formLogin: FormGroup;
  userData: null;
  type: string;
  register: any = false;

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private utilsService: UtilsService,
    private router: Router,
    private prer: PreRegistroService
  ) {

  }

  public loginEmailAndPass() {
    this.authService.signInWithEmailAndPassword(
      this.formLogin.value.email,
      this.formLogin.value.password
    ).then(
      (credentials: any) => {
        const res = this.authService.SetUserData();
        if (this.type !== 'preregister') {
          this.router.navigateByUrl('/home');
        }
        this.toastService.showSuccess('User logged');
      }).catch(
      error => this.toastService.showDanger(error.message)
    );
  }

  public loginRedes(red: string) {
    if (red === 'facebook') {
      this.authService.loginFacebook().then((data: any) => {
        const res = this.authService.SetUserData();
        if (this.type !== 'preregister') {
          this.router.navigateByUrl('/home');
        }
      }).catch((error: any) => {
        console.log(error);
        this.toastService.showDanger('Algo salio mal');
      });
    }
    if (red === 'google') {
      this.authService.loginGoogle().then((data: any) => {
        this.authService.SetUserData();
        if (this.type !== 'preregister') {
          this.router.navigateByUrl('/home');
        }
      }).catch((error: any) => {
        console.log(error);
        this.toastService.showDanger('Algo salio mal');
      });
    }
    if (red === 'twitter') {
      this.authService.loginTwitter();
    }
    // this.toastService.showInfo(`Proximamente login con tu red favorita: ${red}`);
  }

  public ngOnInit() {
    console.log(this.type);
    this.formLogin = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        customPatternValidator(this.utilsService.patterns.email)
      ]),
      password: new FormControl('', [
        Validators.required
      ])
    });
  }

  irregister() {
    if (this.type !== 'preregister') {
      this.router.navigateByUrl('/registro');
    }
    if (this.type === 'preregister') {
      this.register = true;
    }
  }

  irolvide() {
    if (this.type !== 'preregister') {
      this.router.navigateByUrl('/resetpassword');
    }

  }

  logout() {
    this.authService.logout();
  }

  get email() {
    return this.formLogin.get('email');
  }

  get password() {
    return this.formLogin.get('password');
  }

}
