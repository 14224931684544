// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripeKey: 'pk_live_7izvujkiCQ01oj7bdvSQolrH00BWVqksZs',
  // stripeKey: 'pk_test_5MkC7WveBmZitbSA1smEJ3jl00kuMbzYBE',
  firebaseConfig: {
    apiKey: 'AIzaSyA0RqPvSkeybcUkDn-f8LII2ME_7cJIqx0',
    authDomain: 'todoespecialistas.com',
    databaseURL: 'https://todoespecialistas.com',
    projectId: 'todo-especialistas',
    storageBucket: 'todo-especialistas.appspot.com',
    messagingSenderId: '31449834557',
    appId: '1:31449834557:web:163e24896cf1d865'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
