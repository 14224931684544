// export interface User {
//     create: number;
//     email: string;
//     name: string;
//     phone: string;
//     roles: Array<'user' | 'trade' | 'business' | 'admin'>;
//     update: number;
//     uid: string;
// }
import { Business } from './business';

export class User {
  constructor(
    public email: string,
    public name: string,
    public phone: string,
    public business?: string[],
    public create?: any,
    public roles?: Array<'user' | 'trade' | 'business' | 'admin' | 'nolog'>,
    public update?: number,
    public uid?: string,
    public city?: string
  ) {
    this.business = business ? business : [];
    this.create = create ? create : new Date().getTime();
    this.roles = roles ? roles : ['user'];
    this.update = update ? update : new Date().getTime();
    this.uid = uid ? uid : '';
  }
}
